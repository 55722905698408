import { datadogLogs, HandlerType } from '@datadog/browser-logs';

const DATADOG_CLIENT_TOKEN = 'pub57592799f74a821167ee96b06b7f089d';

export const initLogger = ({ service }: { service: 'blender' | 'onboarding' }) => {
  if (datadogLogs.getInternalContext()?.session_id) {
    return;
  }

  const isLocalHost = process.env.NEXT_PUBLIC_ENVIRONMENT === 'localhost';

  datadogLogs.init({
    clientToken: DATADOG_CLIENT_TOKEN,
    env: process.env.NEXT_PUBLIC_ENVIRONMENT,
    forwardConsoleLogs: 'all',
    forwardErrorsToLogs: true,
    forwardReports: 'all',
    service,
    site: 'datadoghq.eu',
    version: process.env.NEXT_PUBLIC_VERSION,
  });

  datadogLogs.logger.setHandler(isLocalHost ? [HandlerType.console] : [HandlerType.http]);
};
