'use client';

import { Stack, useTheme } from '@mui/material';
import { useTranslation } from 'next-export-i18n'; // TODO: remove i18n

import { FacebookRounded, Instagram, LinkedIn, LocalPhone, LocationOn, MailOutline, YouTube } from '../icons';
import { Box } from './Box/Box';
import { Container } from './Container';
import { Divider } from './Divider';
import { Link } from './Link';
import { Typography } from './Typography/Typography';

export const Footer = ({ email }: { email?: string }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const contactList = [
    { icon: <LocationOn />, text: t('shared.company.address') },
    { icon: <LocalPhone />, text: t('shared.company.phone') },
    { icon: <MailOutline />, text: email },
  ];
  const socialMedia = [
    { icon: <FacebookRounded />, url: 'https://www.facebook.com/TokiPower/' },
    { icon: <YouTube />, url: 'https://www.youtube.com/channel/UC88ER749i5WeRQrxkfWGyFw' },
    { icon: <Instagram />, url: 'https://www.instagram.com/toki.bg/' },
    { icon: <LinkedIn />, url: 'https://www.linkedin.com/company/toki' },
  ];

  return (
    <Box
      width="100%"
      mt="auto"
      sx={{ background: theme.palette.blue.dark, color: theme.palette.common.white }}
      textAlign="center"
    >
      <Container>
        <Typography variant="h4">{t('shared.company.name')}</Typography>
        <Stack direction="column" alignItems="center" gap={1} my={3}>
          {contactList.map(({ text, icon }) => (
            <Stack direction="row" gap={1} key={text}>
              {icon}
              <Typography variant="body1">{text}</Typography>
            </Stack>
          ))}
        </Stack>
        <Box py={2} display="flex" justifyContent="center">
          {socialMedia.map(({ url, icon }) => (
            <Link href={url} newTab key={url}>
              <Box
                width={40}
                height={40}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  '&:hover': {
                    backgroundColor: theme.palette.grey[100],
                  },
                  bgcolor: theme.palette.common.white,
                  borderRadius: '50%',
                  color: theme.palette.blue.dark,
                  mx: 1,
                }}
              >
                {icon}
              </Box>
            </Link>
          ))}
        </Box>
      </Container>

      <Divider sx={{ bgcolor: 'gray.main', mx: 3, my: 0 }} />

      <Box sx={{ backgroundColor: theme.palette.blue.dark }}>
        <Container>
          <Box display="flex" justifyContent="space-between" sx={{ flexDirection: { md: 'row', xs: 'column' } }}>
            <Typography>{`${t('shared.company.toki')} ${new Date().getFullYear()}`}</Typography>
            <Box display="flex" justifyContent="space-between">
              <Link
                color="common.white"
                href="https://cdn.prod.website-files.com/65e73d7a1d155119f24d9741/678e5901264f3e1dce572a60_%D0%9E%D0%B1%D1%89%D0%B8%20%D1%83%D1%81%D0%BB%D0%BE%D0%B2%D0%B8%D1%8F_20.01.2025.pdf"
                mr={2}
              >
                {t('shared.common.termsOfUse')}
              </Link>
              <Link href="https://www.toki.bg/politika-za-poveritelnost" color="common.white">
                {t('shared.common.privacyPolicy')}
              </Link>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};
